import { configureStore } from '@reduxjs/toolkit'
import personSlice from './slices/personSlice'
import { api } from './api/api'
import dateSlice from './slices/dateSlice'
import stepsSlice from './slices/stepsSlice'
import replicasSlice from './slices/replicasSlice'

export const store = configureStore({
  reducer: {
    personSlice,
    dateSlice,
    stepsSlice,
    replicasSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
