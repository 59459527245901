import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IPropsReplicaSettings } from '../../data/stepsModels'

const initialState: IInitial = {
  replicasData: [],
}

interface IInitial {
  replicasData: IPropsReplicaSettings[]
}

const replicasSlice = createSlice({
  name: 'replicas',
  initialState,
  reducers: {
    setReplicas: (state, action: PayloadAction<IPropsReplicaSettings[]>) => {
      state.replicasData = action.payload
    },
    changeReplica: (state, action: PayloadAction<IPropsReplicaSettings>) => {
      const index = state.replicasData.findIndex(
        (replica) => action.payload.id === replica.id
      )

      if (index > -1) {
        state.replicasData[index] = {
          ...action.payload,
        }
      }
    },
    addReplica: (state, action: PayloadAction<{ product: string }>) => {
      if (state.replicasData.length < 4)
        state.replicasData.push({
          id: state.replicasData.length + 1,
          replica: '',
          navigate: [],
          product: action.payload.product,
        })
    },
    clearReplicas: (state) => {
      state.replicasData = []
    },
    deleteReplica: (state, action: PayloadAction<string>) => {
      state.replicasData = state.replicasData.filter(
        (replica) => String(replica.id) !== action.payload
      )
    },
  },
})

export default replicasSlice.reducer
export const {
  setReplicas,
  clearReplicas,
  changeReplica,
  addReplica,
  deleteReplica,
} = replicasSlice.actions
