export const productAlfa = "Альфа-банк"
export const productSovcombank = "Совкомбанк - Халва"
export const productTinkoff = "Тинькофф"

export const course1 = "Курс 1"
export const course2 = "Курс 2"
export const course3 = "Курс 3"
export const course4 = "Курс 4"
export const course5 = "Курс 5"
export const course6 = "Курс 6"
export const course7 = "Курс 7"
export const course8 = "Курс 8"
export const course9 = "Курс 9"
export const course10 = "Курс 10"
export const course11 = "Курс 11"
export const course12 = "Курс 12"
export const course13 = "Курс 13"
export const course14 = "Курс 14"
export const course15 = "Курс 15"
export const course16 = "Курс 16"
export const course17 = "Курс 17"
export const course18 = "Курс 18"
export const course19 = "Курс 19"
export const course20 = "Курс 20"

export const courses = [{ link: 'alfa', name: productAlfa }, { link: 'halva', name: productSovcombank }, { link: 'tinkoff', name: productTinkoff },
{ link: 'course1', name: course1 }, { link: 'course2', name: course2 }, { link: 'course3', name: course3 }, { link: 'course4', name: course4 }, { link: 'course5', name: course5 },
{ link: 'course6', name: course6 }, { link: 'course7', name: course7 }, { link: 'course8', name: course8 }, { link: 'course9', name: course9 }, { link: 'course10', name: course10 },
{ link: 'course11', name: course11 }, { link: 'course12', name: course12 }, { link: 'course13', name: course13 }, { link: 'course14', name: course14 }, { link: 'course15', name: course15 },
{ link: 'course16', name: course16 }, { link: 'course17', name: course17 }, { link: 'course18', name: course18 }, { link: 'course19', name: course19 }, { link: 'course20', name: course20 }]


export const URL = "https://trainer.contact-center.su/"